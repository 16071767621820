<template>
  <div
    :class="{ banner: active, 'banner-static': !active }"
    :style="style"
    ref="banner"
  >
    <img
      :class="{ 'banner-item': active, 'banner-item-static': !active }"
      :src="src"
      alt=""
    />
    <div class="real-item" :style="realItemStyle"></div>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
    src: String,
  },
  data() {
    return {
      scrollY: 0,
    };
  },
  computed: {
    style() {
      if (!this.active) return "";
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      return `height:${((windowHeight - this.scrollY) / windowHeight) * 100}vh`;
    },
    realItemStyle() {
      if (this.active) {
        return `background-image:url('${this.src}')`;
      } else {
        return "display:none";
      }
    },
  },
  created() {
    this.listenerFunction();
  },
  destroyed() {
    this.stopListenerFunction();
  },
  methods: {
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    stopListenerFunction(e) {
      document.removeEventListener("scroll", this.handleScroll, true);
    },
    handleScroll() {
      const topDistance = this.$refs.banner.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const elemHeight = this.$refs.banner.clientHeight;
      const isInViewport =
        topDistance <= windowHeight && topDistance >= 0 - elemHeight;
      if (isInViewport) {
        this.scrollY = window.pageYOffset;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  overflow: hidden;
  font-size: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: linear-gradient(180deg, #3f63ff, #3b41f1);
  .banner-item {
    max-width: calc(100% - 20px);
    max-height: 80%;
    position: absolute;
    border: none;
    visibility: hidden;
  }
  .real-item{
    width: 100%;
    height: 80%;
    margin: 10% 0;
    padding: 0 20px;
    z-index: 5;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-origin: content-box;
  }
}
.banner-static {
  width: 100%;
  overflow: hidden;
  font-size: 0;
  .banner-item-static {
    width: 100%;
    border: none;
  }
}
</style>
