<template>
  <div class="detail">
    <Banner
      :src="require('@/assets/images/banner02.jpg')"
      :active="false"
    ></Banner>
    <div class="main">
      <div class="links">
        <div
          class="links-item links-item-default"
          @click="handleLinks('intro')"
        >
          公司介绍
        </div>
        <div class="links-item" @click="handleLinks('timeline')">发展历程</div>
        <div class="links-item" @click="handleLinks('honor')">获奖荣誉</div>
        <div class="links-item" @click="handleLinks('partner')">合作伙伴</div>
      </div>
      <Divider></Divider>
      <div class="intro" ref="intro">
        <div class="section-title">
          <img src="~@/assets/images/intro.png" alt="" />
        </div>
        <div class="intro-main">
          <div class="img">
            <img src="~@/assets/images/introduction.jpg" alt="" />
          </div>
          <div class="text">
            <div class="normal">
              SENMIAO TECHNOLOGY
              LIMITED（简称“森淼科技”）是一家于2018年3月在美国纳斯达克上市的科技企业，股票代码AIHS。
            </div>
            <br />
            <div class="normal">
              现公司旗下业务主要包含：网约车运力运营服务、网约车平台运营、融资租赁、互联网科技。
            </div>
            <br />
            <div class="normal">
              公司依托科技创新，运用大数据、云计算等技术手段，将网约车出行业务模式中的司机、乘客、出行平台、运力服务、司管服务等有效链接，构建服务于普罗大众的网约车服务生态圈。
            </div>
          </div>
        </div>
      </div>
      <div class="timeline" ref="timeline">
        <div class="section-title">
          <img src="~@/assets/images/devel.png" alt="" />
        </div>
        <div class="timeline-main">
          <img src="~@/assets/images/development.png" alt="" />
        </div>
      </div>
      <div class="honor" ref="honor">
        <div class="section-title">
          <img src="~@/assets/images/honor.png" alt="" />
        </div>
        <div class="honor-main">
          <div class="text">
            <div class="row">
              <span class="time">2018年</span>
              <span class="cross">——</span>
              <span class="tips"
                >四川省首家美国纳斯达克交易所成功挂牌上市企业</span
              >
            </div>
            <div class="row">
              <span class="time">2019年</span>
              <span class="cross">——</span>
              <span class="tips"
                >获得滴滴出行旗下小桔租车2019年“百佳合作伙伴奖”</span
              >
            </div>
            <div class="row">
              <span class="time">2020年</span>
              <span class="cross">——</span>
              <span class="tips">公司上市纪念品被成都市高新区档案馆收藏</span>
            </div>
            <div class="row">
              <span class="time">2020年</span>
              <span class="cross">——</span>
              <span class="tips">获得四川省财金互动政策上市补助奖励</span>
            </div>
            <div class="row">
              <span class="time">2020年</span>
              <span class="cross">——</span>
              <span class="tips">获得成都市武侯区人民政府产业发展资金扶持</span>
            </div>
          </div>
          <div class="img">
            <img src="~@/assets/images/phonor.jpg" alt="" />
          </div>
        </div>
      </div>
      <div class="partner" ref="partner">
        <div class="section-title">
          <img src="~@/assets/images/part.png" alt="" />
        </div>
        <div class="partner-main">
          <img src="~@/assets/images/partner01.png" alt="" />
          <!-- <img src="~@/assets/images/partner02.jpg" alt="" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "./Banner";
import Divider from "./Divider";
export default {
  components: { Banner, Divider },
  data() {
    return {
      whichIsInViewport: "intro",
    };
  },
  created() {},
  destroyed() {},
  methods: {
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    stopListenerFunction() {
      document.removeEventListener("scroll", this.handleScroll, true);
    },
    handleScroll() {
      const list = ["intro", "timeline", "honor", "partner"];
      list.some((ref) => {
        const windowHeight = window.innerHeight;
        const topDistance = this.$refs[ref].getBoundingClientRect().top;
        const elemHeight = this.$refs[ref].clientHeight;
        const isInViewPort =
          topDistance <= windowHeight && topDistance >= 80 - elemHeight;
        if (isInViewPort) {
          this.whichIsInViewport = ref;
          return true;
        } else {
          return false;
        }
      });
    },
    handleLinks(ref) {
      const topDistance = this.$refs[ref].getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const elemHeight = this.$refs[ref].clientHeight;
      const isFullInViewport =
        topDistance <= windowHeight - elemHeight && topDistance >= 0;
      if (!isFullInViewport) {
        let scrollPos;
        console.log(document.documentElement.scrollTop);
        scrollPos = document.documentElement.scrollTop; //Firefox、Chrome、ie、Edge
        function setScrollTop(scroll_top) {
          document.documentElement.scrollTop += scroll_top; //Firefox、Chrome、ie
          document.body.scrollTop += scroll_top; //Edge
        }
        setScrollTop(topDistance - 80);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  max-width: 1280px;
  padding-bottom: 40px;
  margin: 0 auto;
  .links {
    display: flex;
    justify-content: center;
    .links-item {
      user-select: none;
      padding: 18px 13px 15px 13px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #171717;
      border-bottom: 3px solid transparent;
      transition: 0.3s;
      &:nth-of-type(n + 2) {
        margin-left: 72px;
      }
      &.links-item-default {
        color: #157fe7;
        border-bottom: 3px solid #157fe7;
      }
    }
    &:hover {
      .links-item {
        color: #171717;
        border-bottom: 3px solid transparent;
      }
      .links-item:hover,
      .links-item:focus {
        color: #157fe7;
        border-bottom: 3px solid #157fe7;
      }
    }
  }
  .section-title {
    margin: 30px 0;
    text-align: center;
  }
  .intro {
    margin: 0 20px;
    .intro-main {
      max-width: 1080px;
      margin: 0 auto;
      display: flex;
      align-items: stretch;
      .text {
        flex: 1 1 auto;
        margin-left: 25px;
        .normal {
          width: 100%;
          line-height: 32px;
          font-size: 14px;
          color: #2e2020;
        }
      }
    }
  }
  .timeline {
    overflow: hidden;
    .timeline-main {
      text-align: center;
      img {
        max-width: 100%;
      }
    }
  }
  .honor {
    overflow: hidden;
    margin: 0 10px;
    .honor-main {
      display: flex;
      justify-content: center;
      align-items: stretch;
      .text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .row {
          display: flex;
          align-items: center;
          .time {
            flex: 0 0 auto;
            font-size: 20px;
            font-weight: bold;
            color: #171717;
          }
          .cross {
            margin: 0 10px;
            color: #e5e5e5;
          }
          .tips {
            font-size: 14px;
            color: #171717;
          }
        }
      }
      .img {
        margin-left: 40px;
        display: flex;
        align-items: center;
        text-align: left;
        img {
          width: 100%;
        }
      }
    }
  }
  .partner {
    overflow: hidden;
    .partner-main {
      text-align: center;
      img {
        max-width: 100%;
      }
    }
  }
}
</style>
