<template>
  <div class="divider"></div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.divider{
  width: 100%;
  height: 1px;
  margin: 0 auto;
  background-color: #eeeeee;
}
</style>